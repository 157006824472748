// set port of backend
const PORT                = 7244;     // for my private

// base url
//const baseUrl = 'http://localhost:7044';                // my private local

const USER_SERVER   = '/api/v001';

//const SERVER_URL = 'http://127.0.0.1:7244';
const SERVER_URL = 'https://rvcar.zzzmate.com';

const REACT_APP_APPLE_CLIENT_ID="heraigirl.heraigirl.com";
const REACT_APP_APPLE_REDIRECT_URI="https://dev.herchatgpt.com";
//const REACT_APP_GOOGLE_CLIENT_ID="562347218682-nsa5j871j9dheh2mhb13b30hndv5k447.apps.googleusercontent.com"
const REACT_APP_GOOGLE_CLIENT_ID="749475098575-oofm1cvi49lcepfl4qd6qmt7vcaioa3j.apps.googleusercontent.com";
const TYPECAST_TTS_TOKEN = '__pltPrqniwwKk2tG8AMwSKKcqwxaGEXiUBegQa4v7mPK';

module.exports = {
    PORT,
    USER_SERVER,
    REACT_APP_GOOGLE_CLIENT_ID,
    TYPECAST_TTS_TOKEN,
    SERVER_URL
}
