import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Input, Checkbox, Spin, Divider } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import {sleep} from "../../utils/utilfunc"
import axios from "axios";
import Swal from 'sweetalert2';
import MP3Player from "../../utils/MP3Player";
import PCMPlayer from '../../utils/PCMPlayer';
import MyAudioRecorder from "../../utils/MyAudioRecorder";
import {USER_SERVER, SERVER_URL} from '../../../config'
import {removeCookie} from "../../../cookie/cookie";
import {io} from "socket.io-client"

const { TextArea } = Input;

function Chat(props) {
    //let user = useSelector((state) => state.user);
    const vrecorder = useRef(null);
    //const UserInfo = user.userData;     // UserInfo={token, user_id}
    //console.log('userInfo', UserInfo);
    const player = useRef(null);
    const socket = useRef(null);
    const [HistoryList, setHistoryList] = useState([]);
    const [WaitAnswer, setWaitAnswer] = useState(false);
    const [Question, setQuestion] = useState('');
	const [RecStatus, setRecStatus] = useState('ready');			// ready, prepare_rec, rec, prepare_stop
	const [MicMode, setMicMode] = useState(false);

    //console.log('Chat rerender', player.current);

    // tts 관련
    const [TTSMode, setTTSMode] = useState(false);

    useEffect(() => {
        //player.current = new MP3Player();
        player.current = new PCMPlayer();        
    }, []);

    useEffect(() => {
        get_chat_history();
    }, [props.UserInfo]);

    // useEffect(() => {
    //     const reader = new FileReader();
    //     reader.onload = function(e) {
    //         const arrayBuffer = e.target.result;
    //         const uint8Array = new Uint8Array(arrayBuffer);
    //         const player = new MP3Player();
    //         player.play(uint8Array);
    //     };
    //     reader.readAsArrayBuffer('sample.mp3');
    // }, []);

    // useEffect(() => {
    //     // url이 null이 아닐 때만 재생
    //     if (TTS_base64) {
    //         audioRef.current.src = `data:audio/mp3;base64,${TTS_base64}`;
    //         audioRef.current.play().catch(error => console.log("재생 중 오류 발생:", error));
    //     }
    // }, [TTS_base64]); // url 상태가 변경될 때마다 이 효과를 실행
    
    
    async function get_chat_history() {
        if (!props.UserInfo?.token)
            return;

        const res = await axios.post(`${USER_SERVER}/get_chat_history`, {user_id:props.UserInfo.user_id, token:props.UserInfo.token});
        console.log('get_chat_history--------->', res);
        if (res.data?.success) {
            setHistoryList(res.data.data);
        } else {
            show_error(res);
        }
    }

    // error 나면 에러 정보를 디스플레이
    function show_error(res) {
        console.log('show error:', res);
        const tdata = res.data.data;
        if (typeof tdata ==='object' && 'title' in tdata){
            Swal.fire({
                title: res.data.data.title,
                text: res.data.data.description,
                icon: 'info',
                confirmButtonText: '확인'
              });
            if (tdata.type=='tokenExpired'){
                removeCookie('w_auth');
                props.history.push("/login");    
            }
        } else {
            Swal.fire({
                title: 'Error',
                text: res.data.data,
                icon: 'error',
                confirmButtonText: '확인'
              });
        }
    }

    async function onClickSend(){
        await send_question(Question);
    }

    // async function send_question(question){
    //     setWaitAnswer(true);
    //     const currentDate = new Date();
    //     const dataToCommit = {
    //         token:props.UserInfo.token, 
    //         user_id:props.UserInfo.user_id,
    //         question:question.trim(),
    //     }
        
    //     const endpoint = TTSMode? 'get_answer_speech' : 'get_answer';
    //     const res = await axios.post(`${USER_SERVER}/${endpoint}`, dataToCommit);
    //     setWaitAnswer(false);
    //     console.log(`${endpoint} --------->`, res);
    //     if (res.data?.success) {
    //         await process_answer(res.data.data);
    //     } else {        
    //         show_error(res);
    //     }
    // }

    function get_key_val(myobj) {
        let mykey=null, myval=null;
        const key_list = Object.keys(myobj);
        if (key_list.length>0) {
            mykey = key_list[0];
            myval = myobj[mykey];
        }
        return [mykey, myval]
    }

    function process_answer(data) {
        const question = data.question;
        const answer = data.answer;
        const control_object = data.control;
        const user_msg = {speaker:'h', message:question};
        const assistant_msg = {speaker:'c', message:answer};
        setHistoryList((prev)=>[...prev, user_msg, assistant_msg]);

        const [control_key, control_val] = get_key_val(control_object);

        if (control_key) {
            props.change_ags_state(control_key, control_val);
        }
    }


    async function onClickReset() {
        const res = await axios.post(`${USER_SERVER}/reset_history`, {token:props.UserInfo.token, user_id:props.UserInfo.user_id});
        console.log('reset_history--------->', res);
        if (res.data?.success) {
            await get_chat_history();
            await props.my_send_ags();
        } else {
            alert('Error:'+res.data.data);
        }
    }


    const render_histlist = HistoryList  && HistoryList.map(function(hist, index){
        const prompt = hist.speaker==='h'? 'User:  ':'Assistant:  ';
        const func_msg = (hist?.gptfunc)? '  (' + hist.gptfunc + ')' : '';
        return (
            <div key={index}>
                <Row>
                    <Col offset={1} span={22}>
                        {prompt}{hist.message}{func_msg}
                    </Col>
                </Row>
                <Row>
                    <Col offset={1} span={22}>
                        {hist.speaker==='c' && <br/>}
                    </Col>
                </Row>
            </div>
        );
		});


    async function sendDataToBackend(data) {
        try {
            setWaitAnswer(true);
            const formData = new FormData();
            formData.append('audio', data);
            formData.append('user_id', props.UserInfo.user_id);
            formData.append('token', props.UserInfo.token);
            // formData.append('status', JSON.stringify({
            //     parking_remaining_sec: get_parking_remaining_sec()
            // }));

    
            const res = await axios.post(`${USER_SERVER}/get_answer_speech`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            setWaitAnswer(false);
            console.log('get_answer_speech --------->', res);
            if (res.data?.success) {
                await process_answer(res.data.data);
            } else {        
                show_error(res);
            }
    
            console.log('Data sent successfully', res);
        } catch (error) {
            console.error('Error sending data to backend', error);
        }
    }

    async function onClickStart() {
		console.log('onClickStart=', RecStatus);
		if (RecStatus!=='ready') {
			return;
		}

		setRecStatus('prepare_rec');
		try {
			vrecorder.current = new MyAudioRecorder();

			await vrecorder.current.openMic();
			await vrecorder.current.start_recording();
			setRecStatus('rec');
		} catch (err) {
			console.log(err.message);	// device가 없어서 error가 난 것으로 추정
			setRecStatus('ready');
		}
	}

	// stop recording
	async function onClickStop() {
		console.log('onClickStop=', RecStatus);
		if (RecStatus!=='rec') {
			return;
		}
		setRecStatus('prepare_stop');
		await vrecorder.current.stop_recording();

		for (let k=0; k<30; k++) {
			const vsign_url = vrecorder.current.get_blob_url();
			if (vsign_url==='too_short') {
				setRecStatus('ready');
				console.log('too short ---------------------------------', k);
				return;
			} else if (vsign_url){
                const data = vrecorder.current.get_recorded_blob();
                await sendDataToBackend(data);
				setRecStatus('ready');
				console.log('======', vsign_url, k)
				return;
			}
			await sleep(100);
		}
		alert('Error');
	}

    async function onClickRec() {
        if (MicMode) {
            await onClickStop();
        } else {
            await onClickStart();
        }

        setMicMode(!MicMode);
    }

    const mic_text = (MicMode)? 'Stop Mic' : 'Start Mic';
    const button_color = (RecStatus==='rec')? 'red' : 'blue';
    const mic_btn_disable  = (RecStatus==='prepare_rec' || WaitAnswer);

    function onChangeTTS(e) {
        setTTSMode(e.target.checked);
    }

    ///////////////////// SOCKET ////////////////////////////////
    // connect
    useEffect(() => {
        if (!socket.current && props.UserInfo?.user_id && props.UserInfo?.user_id!=='unknown') {
            const dataToCommit = {
                user_id:    props.UserInfo.user_id,
                token:      props.UserInfo.token,
            }
            const newSocket = io(SERVER_URL, {query: dataToCommit});
            newSocket.on('connect', () => {
                console.log('Connected to the server');
            });
    
            socket.current = newSocket;
        }

        // 컴포넌트 언마운트시 소켓 연결을 해제합니다.
        return () => {
            if (socket.current) {
                socket.current.disconnect();
                socket.current = null;
            }
        };

    }, [props.UserInfo]);

    useEffect(() => {
        if (socket.current) {
            // 'get_data' 이벤트를 수신하고 데이터를 처리합니다.
            socket.current.on('get_answer_response', (res) => {
                console.log('get_answer_response:', res);
                const data = res.data;
                if (res?.success) {
                    process_answer(data);
                } else {        
                    show_error(data);
                }
                setWaitAnswer(false);
            });

            socket.current.on('audio_chunk', (data) => {
                console.log('data in');
                if (data.byteLength===0) {
                    //player.current.mediaSource.endOfStream();
                } else {
                    const uint8Array = new Uint8Array(data);
                    player.current.appendBuffer(uint8Array);
                }
              });
          

            // 컴포넌트가 언마운트될 때 소켓 연결을 정리합니다.
            //return () => socket.current.disconnect();
        }
    }, [socket.current]);

    async function send_question(question){
        //debugger;
        setWaitAnswer(true);
        const dataToCommit = {
            job_id:     'job_id_123',
            tts:        TTSMode,
            question:   question.trim(),
        }
        
        if (socket.current) {
            socket.current.emit('get_answer', dataToCommit);
        }
    }
    ///////////////////// END OF SOCKET ////////////////////////////////
    
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const playFile = () => {
        if (!file) {
            alert('Please select an MP3 file first.');
            return;
        }
        const reader = new FileReader();
        // reader.onload = function (e) {
        //     const arrayBuffer = e.target.result;
        //     const uint8Array = new Uint8Array(arrayBuffer);
        //     const player = new MP3Player();
        //     player.play(uint8Array);
        // };

        reader.onload = function (e) {
            const arrayBuffer = e.target.result;
            const chunkSize = 4096;     //480*1; // 정의된 청크 크기
            //const player = new MP3Player();

            // 배열 전체를 순회하면서 청크로 나누어 재생
            for (let i = 0; i < arrayBuffer.byteLength; i += chunkSize) {
                const end = Math.min(i + chunkSize, arrayBuffer.byteLength);
                const chunk = new Uint8Array(arrayBuffer.slice(i, end));

                console.log('---', player.current.sourceBuffer);
                player.current.appendBuffer(chunk);                
            }
        };
        reader.readAsArrayBuffer(file);
    };

    function onClickTestGetAnswer(){
       
        if (socket.current) {
            socket.current.emit('test_get_answer');
        }

    }
    
    return (
        <div>
            <h3>Chat</h3> 
            {render_histlist}

            <Row justify="center" align='middle'>
                Input:
                <Input value={Question} onChange={(e)=>setQuestion(e.currentTarget.value)} onPressEnter={onClickSend} style={{width:'85%', margin:10}}/>
            </Row>

            <Row justify="center" align='middle'>
                <Col>
                    <Checkbox disabled={true} onChange={onChangeTTS} checked={TTSMode}>음성출력</Checkbox>
                </Col>
                <Col>
                {
                    WaitAnswer? <Spin/> :
                                <Button type='primary' onClick={onClickSend}  style={{margin:10}}>
                                    Send Text
                                </Button>
                }
                </Col>
                {/* <Col>
                    또는
                    <Button type='primary' onClick={onClickRec} disabled={mic_btn_disable} style={{backgroundColor:button_color}}>{mic_text}</Button>
                </Col> */}
                <Col>
                    <Button type='primary' onClick={onClickReset} style={{marginLeft:30}}>Reset and Restart</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    (text로 입력할 때: Input을 쓰고 &rarr; 'Send Text'를 클릭!)
                </Col>
            </Row>
            {/* <Row>
                <Col>
                    -------------------여기부터는 디버깅용-----------------
                </Col>
            </Row>

            <Row>
                <input type="file" onChange={handleFileChange} accept="audio/pcm" />
                <button onClick={playFile}>Play MP3</button>
            </Row>
            <Row>
                <button onClick={onClickTestGetAnswer}>Test Get Answer</button>
            </Row> */}
        </div>
    )
}

export default Chat
