import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { DownOutlined, UpOutlined, CloseOutlined, MenuOutlined, BellOutlined } from "@ant-design/icons";

import { USER_SERVER } from '../../../../config'
import { useGoogleLogout } from "react-google-login";
//import { GOOGLE_OAUTH_CLIENT_ID } from "../../../../config";
import {removeCookie} from "../../../../cookie/cookie";

const CaseLoged = ({
    user, history,
    isDrawerOpen, isSubOpen, isSubMenuOpen, isNotifyOpen,
    subMenuShow, toggleDrawer, toggleNotificationDrawer, subMenuOpen

}) => {

    // const onLogoutSuccess = () => Swal.fire(("Saved!"), ("Google logout successfully."), "success");

    // const onLogoutFailure = (err) => {
    //     if (loaded) {
    //         Swal.fire({ icon: "error", title: ("Oops..."), text: ("Google Logout Failed!") });
    //     }
    // };

    // const { signOut, loaded } = useGoogleLogout({
    //     clientId: GOOGLE_OAUTH_CLIENT_ID,
    //     onLogoutSuccess: onLogoutSuccess,
    //     onFailure: onLogoutFailure,
    // });

    // const logoutHandler = () => {

    //     // if (user.userData && user.userData.regist_method === "google") {
    //     //     signOut();
    //     // }

    //     axios.post(`${USER_SERVER}/users/logout_admin`, {}).then((response) => {
    //         if (response.status === 200) {
    //             Swal.fire(("Logout!"), ("Logged out successfully."), "success");
    //             history.push("/login");
    //         } else {
    //             Swal.fire({ icon: "error", title: ("Oops..."), text: ("Something went wrong, Logout Failed") });
    //         }
    //     });
    // };

    async function logoutHandler(){
        const response = await axios.get(`${USER_SERVER}/web/logoutgoogle`);
        if (response.status === 200) {
            //await Swal.fire(("Logout!"), ("Logged out successfully."), "success");
            //Cookies.remove('w_auth');
            removeCookie('w_auth');
            history.push("/login");
        } else {
            Swal.fire({ icon: "error", title: ("Oops..."), text: ("Something went wrong, Logout Failed") });
        }
    };

    return (
        <div className="header-menu">
            <div className="hamburger-btn" onClick={toggleDrawer}>
                {isDrawerOpen === true ? <CloseOutlined /> : <MenuOutlined />}
            </div>

            <ul className={isDrawerOpen === true ? "header-menu-ul header-menu-show" : "header-menu-ul"}>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/test">Test</Link>
                </li>
                <li>
                    <Link to="/scenario">Scenario</Link>
                </li>
                <li>
                    <Link to="/develope">Develope</Link>
                </li>
                <li>
                    <Link to="/login" onClick={logoutHandler}> Log Out </Link>
                </li>
            </ul>
        </div>
    );
};

export default CaseLoged;