import React from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { DownOutlined, UpOutlined, CloseOutlined, MenuOutlined } from "@ant-design/icons";

const CaseNotLoged = ({

    history,
    isDrawerOpen, isSubOpen,
    subMenuShow, toggleDrawer

}) => {

    return (
        <div className="header-menu">
            <div className="hamburger-btn" onClick={toggleDrawer}>
                {isDrawerOpen === true ? <CloseOutlined /> : <MenuOutlined />}
            </div>

            <ul className={isDrawerOpen === true ? "header-menu-ul header-menu-show" : "header-menu-ul"}>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/test">Test</Link>
                </li>
                <li>
                    <Link to="/scenario">Scenario</Link>
                </li>
                <li>
                    <Link to="/develope">Develope</Link>
                </li>
                <li>
                    <Link to="/login">Sign In</Link>
                </li>
            </ul>
        </div>
    );
};

export default CaseNotLoged;