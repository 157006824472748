import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Input, Table, Spin, Divider } from 'antd';

function AGS(props) {

    const [editKey, setEditKey] = useState(null);           // 선택된 값의 키를 추적하는 상태
    const [editValue, setEditValue] = useState('');
    


    // 편집을 시작하는 함수
    function handleEdit(key, value) {
        setEditKey(key);
        setEditValue(value);
    };

    // 값을 변경하고 편집 모드를 종료하는 함수
    function handleSave(key) {
        //setMyList(prev => ({ ...prev, [key]: editValue }));
        props.change_ags_state(key, editValue);
        setEditKey(null);
    };

    // 입력 값이 변경될 때 호출되는 함수
    function handleChange(event) {
        setEditValue(event.target.value);
    };



    const render_agsinfo = props.AGSState  && Object.entries(props.AGSState).map(([key, value]) => (
        <tr key={key}>
          <td>{key}</td>
          <td>
            {editKey === key ? (
              <input
                type="text"
                value={editValue}
                onChange={handleChange}
              />
            ) : (
              value
            )}
          </td>
          <td>
            {editKey === key ? (
              <button onClick={() => handleSave(key)}>Save</button>
            ) : (
              <button onClick={() => handleEdit(key, value)}>Edit</button>
            )}
          </td>
          <td>
            {props.descriptionList[key]}
          </td>
        </tr>
      ));


    return (
        <div style={{textAlign:'center'}}>
            <h3>AGS</h3> 

            <Row justify='center'>
                <Col span={12}>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Value</th>
                                <th>Button</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {render_agsinfo}
                        </tbody>
                    </table>
                </Col>
            </Row>

        </div>
    )
}

export default AGS
