import React, {useState, useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { Row, Input, Button } from 'antd';


const { TextArea } = Input;

function LandingPage(props) {

    return (
        <div>
            <Row justify='center' style={{'margin':100}}>
                <h1>RV Car</h1>
            </Row>
        </div>
)
}

export default withRouter(LandingPage)
