import React, { useState } from "react";
import { Row, Col, Input, Button } from 'antd';
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { loginUserId } from "../../../_actions/user_actions";

function LoginPage(props) {

    const dispatch = useDispatch();

    const [UserId, setUserId] = useState("");
    const [Password, setPassword] = useState("");

    function respond_to_result(response) {
        const resdata = response.payload;
        console.log('resdata=', resdata);

        if (resdata.loginSuccess) {
            let message = ("Logged in successfully.");
            Swal.fire(("Success"), message, "success");
            props.history.push("/test");
        } else {
            Swal.fire({ icon: "error", title: ("Oops..."), text: ('Something went wrong!!!') });
        }
    }

    function onChangeUserId(e){
        setUserId(e.target.value);
    }

    function onChangePassword(e){
        console.log(e.target.value);
        setPassword(e.target.value);
    }

    function onClickSignIn(){
        const dataToCommit = {user_id:UserId, password:Password};
        dispatch(loginUserId(dataToCommit)).then((response) => {
            respond_to_result(response);
        });

    }

    return (
        <div>
            <Row justify='center' align='middle' style={{margin:'10px'}}>
                <Col>ID:</Col>
                <Col>
                    <Input onChange={onChangeUserId} placeholder="ID"/>
                </Col>
            </Row>
            <Row justify='center' align='middle' style={{margin:'10px'}}>
                <Col>Password:</Col>
                <Col>
                    <Input onChange={onChangePassword} onPressEnter={onClickSignIn}/>
                </Col>
            </Row>
            <Row justify={'center'}  style={{margin:'10px'}}>
                <Button onClick={onClickSignIn}>Sign In</Button>
            </Row>
        </div>
    );
}

export default withRouter(LoginPage);
