import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Input, Radio, Spin, Divider } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {USER_SERVER, TYPECAST_TTS_TOKEN} from '../../../config'
import Chat from './Chat';
import AGS from './AGS';

const { TextArea } = Input;

function LandingTest(props) {
    const initialList = { 
        //ags
        // generatorMode:          'true',
        // manualOperate:          'true',
        // generatorOperate:       'true',
        // doorLock:               'true',
        parkingBreak:           'true',
        ignition:               'true',
        startTemperature:       '94',

        // control
        waterPump:              'true', 
        waterHeaterLPGas:       'false', 
        waterHeater110v:        'true' ,
        tankHeaterBlack:        'true',
        tankHeaterGray:         'false',

        //levels
        // shorePowerFault:        'false',
        // waterHeaterGasFault:    'false',
        freshWaterTank:         '0',
        blackWaterTank:         '3',
        grayWaterTank:          '1',
        lPGWaterTank:           '50',

        latitude:               '41.6819',
        longitude:              '-85.9767',

        debug_info:             'blabla...'
    };

    const descriptionList = { 
        //ags
        // generatorMode:          'true',
        // manualOperate:          'true',
        // generatorOperate:       'true',
        // doorLock:               'true',
        parkingBreak:           'true/false',
        ignition:               'true/false',
        startTemperature:       '화씨 온도',
  
        // control
        waterPump:              'true/false', 
        waterHeaterLPGas:       'true/false', 
        waterHeater110v:        'true/false' ,
        tankHeaterBlack:        'true/false',
        tankHeaterGray:         'true/false',
  
        //levels
        // shorePowerFault:        'false',
        // waterHeaterGasFault:    'false',
        freshWaterTank:         '0~3',
        blackWaterTank:         '0~3',
        grayWaterTank:          '0~3',
        lPGWaterTank:           '0,10,20,...',
  
        latitude:               '현재 위도',
        longitude:              '현재 경도',

        debug_info:             'blabla...'
    };
  

    console.log('ags start ======================');
    let user = useSelector((state) => state.user);
    const UserInfo = user.userData;     // UserInfo={token, user_id}
    console.log('UserInfo =', UserInfo);

    const [AGSState, setAGSState] = useState(initialList);

    // 30초마다 ags 값을 backend로 보냄
    useEffect(() => {
        if (UserInfo && UserInfo.user_id) {
            my_send_ags();
            const interval = setInterval(my_send_ags, 10000);
        
            // 컴포넌트가 언마운트되거나 종료될 때 인터벌 정리
            return () => clearInterval(interval);
        }
    }, [UserInfo, AGSState]); // 빈 의존성 배열을 사용해 컴포넌트 마운트 시 한 번만 설정
    
    useEffect(() => {
        if (UserInfo && UserInfo.user_id) {
            console.log('ags value changed!!! so send to backend22222');
            my_send_ags();
        }
    }, [UserInfo]);


    function change_ags_state(key, val){
        console.log('change_ags_state', key, val);
        setAGSState(prev => ({ ...prev, [key]: val }));
    }

    async function my_send_ags() {
        console.log('send ags----------------------', UserInfo.user_id, AGSState);

        const res = await axios.post(`${USER_SERVER}/ags/set_params`, {user_id:UserInfo.user_id, change_dict:AGSState});
        //console.log('ags_set_params--------->', res);
        if (res.data?.success) {
            ;
        } else {
            console.log('ERROR', res);
        }
    }


    return (
        <div>
            <Row>
                <Col span={14}>
                    <Chat change_ags_state={change_ags_state} my_send_ags={my_send_ags} UserInfo={UserInfo}/>
                </Col>
                <Col span={8}>
                    <AGS AGSState={AGSState} change_ags_state={change_ags_state} descriptionList={descriptionList}/>
                </Col>
            </Row>

        </div>
    )
}

export default LandingTest
