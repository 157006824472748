
class PCMPlayer {
  constructor() {
    if (!window.MediaSource) {
      console.error('MediaSource API is not supported in your browser.');
      return;
    }
    console.log('PCMPlayer constructor called');
    this.mediaSource = new MediaSource();
    this.audio = document.createElement('audio');
    this.audio.src = URL.createObjectURL(this.mediaSource);
    this.sourceBuffer = null;
    this.queue = []; // 데이터를 저장할 큐

    this.mediaSource.addEventListener('sourceopen', this.handleSourceOpen.bind(this), false);
    document.body.appendChild(this.audio); // 또는 적절한 DOM 요소에 추가
  }

  handleSourceOpen() {
    console.log('handleSourceOpen called');

    const mimeType = 'audio/pcm; codecs="pcm"';
    if (!MediaSource.isTypeSupported(mimeType)) {
      console.error(`MIME type or codec: ${mimeType} is not supported`);
      return;
    }
    this.sourceBuffer = this.mediaSource.addSourceBuffer(mimeType);
    this.sourceBuffer.addEventListener('updateend', this.handleUpdateEnd.bind(this));
    console.log('sourceBuffer=', this.sourceBuffer);
  }

  handleUpdateEnd() {
    console.log('handleUpdateEnd called');

    // 큐에 아직 처리할 데이터가 있으면 계속해서 추가
    if (this.queue.length > 0 && !this.sourceBuffer.updating) {
      this.sourceBuffer.appendBuffer(this.queue.shift());
    }

    // 큐가 비었고, 모든 데이터가 추가되었으며, sourceBuffer가 업데이트 중이 아닐 때
    if (this.queue.length === 0 && !this.sourceBuffer.updating) {
      this.play(); // 재생 시작          
    }
  }

  appendBuffer(data) {
    if (this.sourceBuffer && !this.sourceBuffer.updating) {
      this.sourceBuffer.appendBuffer(data);
    } else {
      this.queue.push(data); // 업데이트 중이면 큐에 데이터 저장
    }
  }

  play() {
    this.audio.play();
  }

  stop() {
    this.audio.pause();
    console.log('stop called', this.mediaSource.readyState);
    if (this.mediaSource.readyState === 'open') {
      console.log('endOfStream');
      this.mediaSource.endOfStream();
    }
    this.audio.currentTime = 0;
  }
}

export default PCMPlayer;